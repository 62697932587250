import { injectable } from "inversify";
import * as moment from 'moment';
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./home-view.html";
import { RidesApiClient } from "../ts/clients/rides-api-client";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import helpModal from "./help-modal.html";
import { HelpModel } from "../ts/models/help-model";
import { Notyf } from "notyf";
import { MailchimpApiClient } from "../ts/clients/mailchimp-api-client";
import { FullscreenLoader } from "./fullscreen-loader";
import { createPopper } from '@popperjs/core';
import Splide from '@splidejs/splide';
import { CuratorApiClient } from "../ts/clients/curator-api-client";
import { TeamApiClient } from "../ts/clients/team-api-client";

@injectable()
export class HomeView {
    private _apiClient: UmbracoApiClient;
    private _translationService: TranslationService;
    private _translations: any;
    private _trenutniIzziv: any;
    private _skupneZmage: any;
    private _novice: any;
    private _endOfChallenge: Date;
    private _timer: any;
    private _socialMediaPostsFacebook: any;
    private _topTeamsRank: any;

    //private _socialMediaPostsInstagram: any;

    public constructor(apiClient: UmbracoApiClient,
        translationService: TranslationService,
        private ridesApiClient: RidesApiClient,
        private teamApiClient: TeamApiClient,
        private mailchimpApiClient: MailchimpApiClient,
        private curatorApiClient: CuratorApiClient,
        private notyf: Notyf,
        private loader: FullscreenLoader) {        
        this._apiClient = apiClient;
        this._translationService = translationService;
        this._translations = null;
        this._trenutniIzziv = null;
        this._skupneZmage = null;
        this._novice = null;
        this._endOfChallenge = new Date();
        this._timer = null;
        this._socialMediaPostsFacebook = null;
        this._topTeamsRank = [];
        //this._socialMediaPostsInstagram = null;
    }

    public async load(): Promise<void> {
        
        try {
            var self = this;
        
            this._trenutniIzziv = await this.ridesApiClient.getTrenutniIzzivStats();
            this._skupneZmage = await this.ridesApiClient.getSkupneZmageStats()
            if(this._trenutniIzziv.isChallengeActive)
            {
                this._topTeamsRank = await this.teamApiClient.getTeamTopRank();
            }
            var response = await this._apiClient.getHomePage();
            this._endOfChallenge = new Date(response.pasica.datumOdstevalnika);
            var noviceResponse = await this._apiClient.getNovice(0, 4);
            this._novice = noviceResponse.Novice;         
            this._socialMediaPostsFacebook = await this.curatorApiClient.getSocialMediaPostsFacebook(0, 10, 300);           
            //this._socialMediaPostsInstagram = await this.curatorApiClient.getSocialMediaPostsInstagram(0, 10, 300);
            var noviceResponse = await this._apiClient.getNovice(0, 4);
            this._novice = noviceResponse.Novice;
            await this._renderData(response);

            this._initVideoObserver();


            this._timer = setInterval(function () { self.showRemaining(self._endOfChallenge) }, 60000);
            this._initButtons();
            
            const popcorn = document.querySelectorAll('#tooltipParent');
            const tooltip = document.querySelectorAll('#tooltip');
            if (popcorn && tooltip) {
                for (var i = 0; i < popcorn.length; i++) {
                    createPopper(popcorn[i], tooltip[i] as HTMLElement, {
                        placement: 'top-start',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    });
                }
            }

            //new Splide('#instagram-feed', {
            //    perPage: 4,
            //    autoScroll: false,
            //    gap: '30px',
            //    drag: 'free',
            //    breakpoints: {
            //        992: {
            //            destroy: true,
            //        },
            //    }
            //}).mount();
            new Splide('#facebook-feed', {
                perPage: 4,
                autoScroll: false,
                gap: '30px',
                drag: 'free',
                pagination: false,
                breakpoints: {
                    992: {
                        destroy: true,
                    },
                }
            }).mount();
        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
        }

        private _initVideoObserver(): void {
            // Find the video element
            const videoElement = document.getElementById('introVideo') as HTMLIFrameElement;
            
            if (!videoElement) {
                console.warn('Video element with ID "introVideo" not found');
                return;
            }
            
            console.log('Setting up YouTube API for video');
            
            // Make sure video URL has proper parameters for the API
            let src = videoElement.getAttribute('src') || '';
            if (!src.includes('enablejsapi=1')) {
                src = src.includes('?') ? 
                    `${src}&enablejsapi=1&mute=1` : 
                    `${src}?enablejsapi=1&mute=1`;
                videoElement.setAttribute('src', src);
            }
            
            // Load the YouTube IFrame Player API
            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName('script')[0];
            if (firstScriptTag && firstScriptTag.parentNode) {
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
            
            // We need to create a global function to handle the API ready event
            (window as any).onYouTubeIframeAPIReady = () => {
                console.log('YouTube API is ready');
                
                // Create a YouTube player instance
                const player = new (window as any).YT.Player('introVideo', {
                    events: {
                        'onReady': () => {
                            console.log('YouTube player is ready');
                            
                            // Create an Intersection Observer to detect when the video is visible
                            const observer = new IntersectionObserver((entries) => {
                                entries.forEach(entry => {
                                    if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
                                        console.log('Video is in view - playing');
                                        player.playVideo();
                                    } else {
                                        console.log('Video is out of view - pausing');
                                        player.pauseVideo();
                                    }
                                });
                            }, {
                                threshold: 0.5, // Trigger when 50% of the video is visible
                                rootMargin: '0px' // No margin
                            });
                            
                            // Start observing the video container
                            const videoContainer = document.querySelector('.video-container');
                            if (videoContainer) {
                                observer.observe(videoContainer);
                            }
                        }
                    }
                });
            };
            
            console.log('YouTube API initialization complete');
        }



    private showRemaining(endOfChallenge: Date) {
        var today = new Date();
        var diffMs = (endOfChallenge.getTime() - today.getTime()); // milliseconds between now & end of Challenge
        if (diffMs < 0) {
            $(".count-down-active").hide();
            $(".count-down-inactive").show();
            if (this._timer) {
                clearInterval(this._timer);
            }
        }
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        $("#countdown-days").html(diffDays.toString());
        $("#countdown-hours").html(diffHrs.toString());
        $("#countdown-minutes").html(diffMins.toString());
    }

    private async _renderData(data: any): Promise<void> {

        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;

        const viewModel = {
            imageUrl: "../img/icon-logout.svg",
            imageKuponUrl: "../img/icon-kuponi.png",
            imageKodaUrl: "../img/koda.jpg",
            skupneZmage: this._skupneZmage,
            trenutniIzziv: this._trenutniIzziv,
            translations: this._translations,
            rank: this._topTeamsRank,
            showRank: this._topTeamsRank.length > 0 && this._trenutniIzziv.isChallengeActive,
            formators: this.getFormators(),
            data: data,
            novice: this._novice,
            socialMediaPostsFacebook: this._socialMediaPostsFacebook,
            //socialMediaPostsInstagram: this._socialMediaPostsInstagram,
            endOfChallenge: this.getEndOfChallenge()
        } as any;

        if (data) {
            viewModel.pasica = data.pasica;
            viewModel.predstavitevIzziva = data.predstavitevIzziva;
            viewModel.kakoZaceti = data.kakoZaceti;
            viewModel.nagrade = data.nagrade;
            viewModel.prijavaNaIzziv = data.prijavaNaIzziv;
            viewModel.kakoZacetiIconL = '../img/' + data.kakoZaceti.ikonaL + '.svg';
            viewModel.kakoZacetiIconS = '../img/' + data.kakoZaceti.ikonaS + '.svg';
            viewModel.kakoZacetiIconD = '../img/' + data.kakoZaceti.ikonaD + '.svg';
        }

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html); 
        
        // Set SEO
        $("#meta-description")[0].setAttribute("content", data.seoDescription);
        $("#meta-og-title")[0].setAttribute("content", data.seoTitle);
        $("#meta-og-description")[0].setAttribute("content", data.seoDescription);
        $("#meta-og-image")[0].setAttribute("content", data.seoImage);
        $("#meta-twitter-title")[0].setAttribute("content", data.seoTitle);
        $("#meta-twitter-description")[0].setAttribute("content", data.seoDescription);
        $("#meta-twitter-image")[0].setAttribute("content", data.seoImage);
        var currentUrl = window.location.href;
        $("#meta-og-url")[0].setAttribute("content", currentUrl);
        $("#meta-twitter-url")[0].setAttribute("content", currentUrl);

        $("#run").on('click', () => {
            const urlFragment = data?.pasica?.gumb?.Url;
        
            if (urlFragment) {
                const targetUrl = `#${urlFragment}`;
                if (window.location.hash === targetUrl) {
                    location.reload();
                } else {
                    window.location.hash = targetUrl;
                }
            }
        });
        
       
    } 

    private getEndOfChallenge() {
        var today = new Date();
        var diffMs = (this._endOfChallenge.getTime() - today.getTime()); // milliseconds between now & end of Challenge
        if (diffMs < 0) {
            $(".count-down-active").hide();
            $(".count-down-inactive").show();
            if (this._timer) {
                clearInterval(this._timer);
            }
            return null;
        }

        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        return {
            days: diffDays,
            hours: diffHrs,
            minutes: diffMins
        };
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'sl';
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        moment.locale(userLocale);
        return {
            dayFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('dddd');
                };
            },
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            fullDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('yyyy-MM-DD');
                };
            },
            decimalFormat: function () {
                return function (timestamp: any, render: any) {
                    var decimal = render(timestamp).trim() as number;
                    return Number(decimal).toLocaleString('sl-SI');
                };
            }
        };
    }

    private _initButtons(): void {
        var stikButtons = $(".btn-stopite-v-stik");
        if (stikButtons && stikButtons.length > 0) {
            for (let i = 0; i < stikButtons.length; i++) {
                stikButtons[i].setAttribute("href", "javascript:void(0)");
                stikButtons[i].addEventListener("click", async () => {
                    await this._initHelp();
                });
            }
        }
    }

    private async _initHelp(): Promise<void> {
        var self = this;

        const helpViewModel = {} as any;

        const modalHtml = Mustache.render(helpModal, helpViewModel);
        $('#helpWrap').html(modalHtml);

        $('#helpModal').modal("show");

        var formElement = $("#helpForm");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", function (ev) {
            ev.preventDefault();
            if (!formElement.valid()) {
                return;
            }

            var request: HelpModel = {
                name: formElement.find("#name").val() as string,
                email: formElement.find("#email").val() as string,
                message: formElement.find("#message").val() as string,
                agreeWithTerms: formElement.find("#agreeWithTerms").prop('checked')
            };


            self.mailchimpApiClient.submitHelp(request)
                .then(async (data) => {
                    if (data && data.success) {
                        self.notyf.success("Podatki uspešno poslani!");
                        $("#helpModal").modal("hide");
                        self.loader.hide();
                    }
                    else if (data && data.message) {
                        self.notyf.error(data.message);
                    }
                    else {
                        self.notyf.error("Prišlo je do napake!");

                    }
                })
                .catch(() => {
                    self.notyf.error("Prišlo je do napake");
                });
        });
    }
}
