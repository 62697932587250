import { injectable } from "inversify";
import * as moment from 'moment';
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./profile-employer-view.html";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import "jquery-validation-unobtrusive";
import { createPopper } from '@popperjs/core';
import SimpleBar from "simplebar";
import { CompanyApiClient } from "../ts/clients/company-api-client";
import { CompanyProfile } from "../ts/models/company-profile";
// import { ModalConfirm } from "./modal-confirm";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";
import { ModalConfirm } from "./modal-confirm";



@injectable()
export class ProfileEmployerView {
    private _umbracoClient: UmbracoApiClient;
    private _companyApi: CompanyApiClient;
    private _translationService: TranslationService;
    private _translations: any;

    private _novice: any;
    private _nasvet: any;
    private _notify: Notyf;
    private _companyProfile: CompanyProfile | undefined;
    private _fullChreenLoader: any;

    public constructor(
        umbracoClient: UmbracoApiClient,
        userApiClient: CompanyApiClient,
        translationService: TranslationService,
        notyf: Notyf,
        fullChreenLoader: FullscreenLoader,
    
    ) {
        this._umbracoClient = umbracoClient;
        this._companyApi = userApiClient;
        this._translationService = translationService;
        this._translations = null;
        this._novice = null;
        this._nasvet = null;
        this._notify = notyf;
        this._fullChreenLoader = fullChreenLoader;
    
    }

    public async load(): Promise<void> {
        try {

            await this._renderData();

            const popcorn = document.querySelectorAll('#tooltipParent');
            const tooltip = document.querySelectorAll('#tooltip');
            if (popcorn && tooltip) {
                for (var i = 0; i < popcorn.length; i++) {
                    createPopper(popcorn[i], tooltip[i] as HTMLElement, {
                        placement: 'top-start',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    });
                }
            }
            $('[data-toggle="tooltip"]').tooltip({
                placement: 'bottom'
            });
        } catch (e) {
            console.log(e);
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(): Promise<void> {

        //Change to company rideds

        var noviceResponse = await this._umbracoClient.getNovice(0, 2);
        this._novice = noviceResponse.Novice;
        var nasvetiResponse = await this._umbracoClient.getNasveti(0, 1);
        if (nasvetiResponse.Nasveti && nasvetiResponse.Nasveti.length > 0) {
            this._nasvet = nasvetiResponse.Nasveti;
        }

        var companyStats = await this._companyApi.commpanyStats();

        var companyChallengeStats  = await this._companyApi.commpanyStatsChallangeStats();

        this._companyProfile = await this._companyApi.copmanyProfile();


        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;


        const viewModel = {
            imageUrl: "../img/icon-logout.svg",
            imageKuponUrl: "../img/icon-kuponi.png",
            imageKodaUrl: "../img/koda.jpg",
            translations: this._translations,
            formators: this.getFormators(),
            novice: this._novice,
            companyStats:companyStats,
            nasvet: null,
            startDateMin: moment(this._companyProfile.contractAppowdTime).format('YYYY-MM-DD'),
            companyChallengeStats: companyChallengeStats,
            companyProfile: this._companyProfile,
        } as any;
        if (this._nasvet && this._nasvet.length > 0) {
            viewModel.nasvet = this._nasvet[0];
        }

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        if (this._companyProfile && this._companyProfile.Users && this._companyProfile.Users.length > 0) {
            new SimpleBar($('#employees')[0], {
                autoHide: false,
                scrollbarMinSize: 5
            });
        }

       

        $('.remove-user').on('click',  async (ev: any) => {
            ev.preventDefault();
            var  companyProfile = await this._companyApi.copmanyProfile();
            console.log(companyProfile);
            var userId = ev.currentTarget.dataset.id;
           
           var data = await ModalConfirm.confirm({
                content: 'Ali ste prepričani, da želite odstraniti uporabnika?',
                confirmType: 'brisanje',
                cancelText: 'Prekliči',
                confirmText: 'Potrdi'
            })

            if(data){
                await  this._companyApi.copmanyRemoveUser(userId)
                .then(async (response) => {
                    if (response.success) {
                        this._renderData();
                    }
                   
                })
                .catch(() => {
                    this._notify.error("Prišlo je do napake pri brisanju uporabnika");
                });
          
            }
            
        });

        $('#exportData').on('click', async (ev: any) => {
            ev.preventDefault();
            var dateFom = $('#startDate').val();
            var dateTo = $('#endDate').val();

            if(dateFom == '' || dateTo == ''){
                this._notify.error('Izberite obdobje za izvoz');
                return;
            }

            try {
                this._fullChreenLoader.show();
                const blob = await this._companyApi.downloadExport(dateFom, dateTo);
                
                // Create a download link
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `izvozi.xlsx`; // or get filename from headers
                
                // Append to body, click and remove
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                
                // Clean up
                window.URL.revokeObjectURL(url);
                
                this._notify.success('Podatki so bili uspešno prenešeni.');
                this._fullChreenLoader.hide();
            } catch (error) {
                this._notify.error('Prišlo je do napake pri izvozu.');
                console.error('Contract download error:', error);
            }
        });
    
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'sl';
        moment.locale(userLocale);
        return {
            dayFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('dddd');
                };
            },
            shortDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('D.M.');
                };
            },
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('D.M.Y');
                };
            },
            fullDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('yyyy-MM-DD');
                };
            },
            decimalFormat: function () {
                return function (timestamp: any, render: any) {
                    var decimal = render(timestamp).trim() as number;
                    return Number(decimal).toLocaleString('sl-SI');
                };
            }
        };
    }
}
