import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./statistika-izzivov-view.html";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { HomeApiClient } from "../ts/clients/home-api-client";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import { RidesApiClient } from "../ts/clients/rides-api-client";
import * as moment from "moment";
import { createPopper } from '@popperjs/core';

@injectable()
export class StatistikaIzzivovView {
    private _desktopApiClient: HomeApiClient;
    private _umbracoApiClient: UmbracoApiClient;
    private _ridesApiClient: RidesApiClient;
    private _translationService: TranslationService;
    private _translations: any;
    private _izzivi: any;
    private _izbraniIzziv: any;
    private _skupneZmageStats: any;

    public constructor(desktopApiClient: HomeApiClient,
        umbracoApiClient: UmbracoApiClient,
        ridesApiClient: RidesApiClient,
        translationService: TranslationService,
        private currentUserAccessor: CurrentUserAccessor) {
        this._desktopApiClient = desktopApiClient;
        this._umbracoApiClient = umbracoApiClient;
        this._ridesApiClient = ridesApiClient;
        this._translationService = translationService;
        this._translations = null;
        this._izzivi = null;
        this._izbraniIzziv = null;
        this._skupneZmageStats = null;
    }

    public async load(): Promise<void> {
        try {
            var response = await this._desktopApiClient.get();
            var statistikaPageData = await this._umbracoApiClient.getStatistikaIzzivovPageData();
            this._izzivi = await this._umbracoApiClient.getIzzivi(true);

            this._izzivi.sort((a: any, b:any) => {
                const dateA = new Date(a.Konec);
                const dateB = new Date(b.Konec);
                return dateB.getTime() - dateA.getTime(); // Descending order (newest first)
            });

            var izzivIndex = this.getIndexIzziva(this._izzivi.length);
            this._izbraniIzziv = this._izzivi[izzivIndex];


            if (this._izbraniIzziv.Indeks != 0) {
                this._skupneZmageStats = await this._ridesApiClient.getSkupneZmageStatsByDate(this._izbraniIzziv.Zacetek, this._izbraniIzziv.Konec);
            }           
            await this._renderData(response.data, statistikaPageData, this._skupneZmageStats);
            this.initIzziviDropdown(izzivIndex);

            const popcorn = document.querySelectorAll('#tooltipParent');
            const tooltip = document.querySelectorAll('#tooltip');
            if (popcorn && tooltip) {
                for (var i = 0; i < popcorn.length; i++) {
                    createPopper(popcorn[i], tooltip[i] as HTMLElement, {
                        placement: 'top-start',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    });
                }
            }
        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private initIzziviDropdown(izbraniIzzivIndex: number) {
        var dropdown = $("#izziv-dropdown");
        dropdown.val(izbraniIzzivIndex);

        console.log("Izziv index: " + izbraniIzzivIndex);
       
        dropdown.on("change", () => {
            var index = Number(dropdown.val());
            location.href = "#/statistika-izzivov/" + index;
        });
    }

    private getIndexIzziva(izziviLength: any) {
        var url = location.href.split("/");
        var index = Number(url[url.length - 1]);
        if (isNaN(index) || index < 0 || index > izziviLength) {
            console.log("Invalid index: " + index);
            index = izziviLength -1;
        }
        return index;
    }

    private async _renderData(data: string, statistikaPageData: any, skupneZmageStats: any): Promise<void> {
        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;
        var currentUser = await this.currentUserAccessor.getUser();
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "text": data,
            translations: this._translations,
            currentUser: currentUser,
            statistikaPageData: statistikaPageData,
            skupneZmage: skupneZmageStats,
            prikaziSkupneZmage: skupneZmageStats != null,
            izzivi: this._izzivi,
            izbraniIzziv: this._izbraniIzziv,
            formators: this.getFormators()
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        // Set SEO
        $("#meta-description")[0].setAttribute("content", statistikaPageData.SeoDescription);
        $("#meta-og-title")[0].setAttribute("content", statistikaPageData.SeoTitle);
        $("#meta-og-description")[0].setAttribute("content", statistikaPageData.SeoDescription);
        $("#meta-og-image")[0].setAttribute("content", statistikaPageData.SeoImage);
        $("#meta-twitter-title")[0].setAttribute("content", statistikaPageData.SeoTitle);
        $("#meta-twitter-description")[0].setAttribute("content", statistikaPageData.SeoDescription);
        $("#meta-twitter-image")[0].setAttribute("content", statistikaPageData.SeoImage);
        var currentUrl = window.location.href;
        $("#meta-og-url")[0].setAttribute("content", currentUrl);
        $("#meta-twitter-url")[0].setAttribute("content", currentUrl);
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'sl';
        moment.locale(userLocale);
        return {
            dayFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('dddd');
                };
            },
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('D.M.');
                };
            },
            fullDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('yyyy-MM-DD');
                };
            },
            fullDateFormat2: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('DD.MM.YYYY');
                };
            },
            decimalFormat: function () {
                return function (timestamp: any, render: any) {
                    var decimal = render(timestamp).trim() as number;
                    return Number(decimal).toLocaleString('sl-SI');
                };
            }
        };
    }
}
