import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { CompanyProfile } from "../models/company-profile";

@injectable()
export class CompanyApiClient extends ApiClientBase {
    public name = "UserApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async companySearchOptions(prepareRequest: (request: JQueryAjaxSettings) => void): Promise<JQuery.AjaxSettings> {
        return await this._createAjaxOptionsWithToken('/company/get', 'GET', "", false, "json", undefined, undefined, prepareRequest);
    }

    public async getByUser(): Promise<any> {
        return await this._callApi('/company/GetByUser', 'GET', "", false, "json", undefined, undefined);
    }

    public async addContract(data: any): Promise<boolean> {
    
        return await this._callApi('/company/addContract', 'POST', JSON.stringify(data), false, "");
    }

    public async downloadContract(id: number): Promise<Blob> {
    
        return await this._downloadFile(`/company/${id}/Download`);
    }

    public async copmanyProfile(): Promise<CompanyProfile> {
    
        return await this._callApi('/company/profile', 'GET', "", false, "json", undefined, undefined);
    }

    public async copmanyRemoveUser(idUser:number): Promise<any> {
    
        return await this._callApi(`/company/${idUser}/removeUser`, 'POST', "", false, "");
    }

    public async commpanyStats(): Promise<any> {
    
        return await this._callApi('/company/getCompanyStats', 'GET', "", false, "json", undefined, undefined);
    }

    public async commpanyStatsChallangeStats(): Promise<any> {
    
        return await this._callApi('/company/getChallengedStats', 'GET', "", false, "json", undefined, undefined);
    }

    public async downloadExport(dateFom:any, dateTo:any) : Promise<Blob> 
    {
        return await this._downloadFile(`/company/GetUsersDataForExport?dateFrom=${dateFom}&dateTo=${dateTo}`);

    }


    public async downloadTemplate() : Promise<Blob> {
    
            return await this._downloadFile(`/company/download-template`);
    
    }


    
} 