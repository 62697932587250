import * as Mustache from "mustache";
import template from "./../../views/notifications-modal.html";
import { initializeApp, FirebaseApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken, Messaging } from "firebase/messaging";
import { UserApiClient } from "../clients/users-api-client";
import { WebPushApiClient } from "../clients/web-push-api-client";

interface FirebaseConfig {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

interface NotificationPayload {
    notification?: {
        title?: string;
        body?: string;
        icon?: string;
    };
    data?: {
        redirectUrl?: string;
    };
}

export class NotificationsManager {
    private _initialized: boolean = false;
    private _firebaseApp: FirebaseApp | null = null;
    private _messaging: Messaging | null = null;
    private _currentToken: string | null = null;
    private _serviceWorkerRegistration: ServiceWorkerRegistration | null = null;

    private readonly firebaseConfig: FirebaseConfig = {
        apiKey: "AIzaSyDaFTg3Pe_eLLLnHlRtpjonOj6H82GQcjk",
        authDomain: "web-push-notifications-32905.firebaseapp.com",
        projectId: "web-push-notifications-32905",
        storageBucket: "web-push-notifications-32905.appspot.com",
        messagingSenderId: "152298474225",
        appId: "1:152298474225:web:4d0147cc8ec91d0a707750",
        measurementId: "G-BYZNS3JYEQ"
    };

    private readonly VAPID_KEY = 'BC_CQwKmlrRGV9KcZb6EJbkBGH3OzdHcA320ZB7c1VgA3qfs3ZWXQOr2s4uMCcsH6Ri1SMYZq-Spl4LVk6nIO64';

    public async initNotificationsModal(userApiClient: UserApiClient, webPushApiClient: WebPushApiClient): Promise<void> {
        if (this._initialized) {
            return;
        }

        try {
            if (!this.checkPrerequisites()) {
                return;
            }

            const userInfo = await userApiClient.getCurrentUserInfo();
            if (!userInfo || window.location.hash.startsWith("#/registracija")) {
                return;
            }

            await this.initializeFirebase();
            this.renderNotificationModal();
            await this.handleNotificationPermission(webPushApiClient);
            
            this._initialized = true;
        } catch (error) {
            console.error('Failed to initialize notifications:', error);
            this._initialized = false;
        }
    }

    private checkPrerequisites(): boolean {
        if (!('Notification' in window)) {
            console.log('Notifications not supported');
            return false;
        }

        if (!('serviceWorker' in navigator)) {
            console.log('Service Workers not supported');
            return false;
        }

        if (!this.getSupportedOS()) {
            console.log('Unsupported operating system');
            return false;
        }

        return true;
    }

    private async initializeFirebase(): Promise<void> {
        if (!this._firebaseApp) {
            this._firebaseApp = initializeApp(this.firebaseConfig);
            this._messaging = getMessaging(this._firebaseApp);
        }
    }

    private async handleNotificationPermission(webPushApiClient: WebPushApiClient): Promise<void> {
        switch (Notification.permission) {
            case "granted":
                await this.setupNotifications(webPushApiClient);
                break;
            case "default":
                await this.requestPermissionAndSetup(webPushApiClient);
                break;
            case "denied":
                console.log('Notifications denied');
                break;
        }
    }

    private async setupNotifications(webPushApiClient: WebPushApiClient): Promise<void> {
        try {
            this._serviceWorkerRegistration = await this.registerServiceWorker();
            
            if (!this._serviceWorkerRegistration || !this._messaging) {
                console.error('Missing required components:', {
                    registration: !!this._serviceWorkerRegistration,
                    messaging: !!this._messaging
                });
                throw new Error('Failed to initialize notification components');
            }
    
            // Add debug logging
            console.log('Attempting to get token with:', {
                vapidKey: this.VAPID_KEY?.substring(0, 10) + '...',
                registration: this._serviceWorkerRegistration,
                messaging: this._messaging
            });
    
            try {
                const token = await getToken(this._messaging, {
                    vapidKey: this.VAPID_KEY,
                    serviceWorkerRegistration: this._serviceWorkerRegistration
                });
    
                console.log('Token retrieved:', token ? 'success' : 'null');
    
                if (token) {
                    this._currentToken = token;
                    await webPushApiClient.refreshToken(token);
                    this.setupMessageHandler();
                    this.setupPermissionChangeListener(webPushApiClient);
                } else {
                    console.error('Token retrieval failed - no token returned');
                }
            } catch (tokenError) {
                console.error('Token retrieval failed:', tokenError);
                // Check if Firebase is properly initialized
                if (!this._firebaseApp) {
                    await this.initializeFirebase();
                }
                throw tokenError;
            }
        } catch (error) {
            console.error('Failed to setup notifications:', error);
            throw error;
        }
    }

    private async registerServiceWorker(): Promise<ServiceWorkerRegistration> {
        try {
            // First try to get existing registration
            let registration = await navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js');
            
            if (!registration) {
                registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                    scope: '/'
                });
                await navigator.serviceWorker.ready;
            }

            return registration;
        } catch (error) {
            console.error('Service Worker registration failed:', error);
            throw error;
        }
    }

    private async requestPermissionAndSetup(webPushApiClient: WebPushApiClient): Promise<void> {
        try {
            $('#notifModal').modal('show');
            const permission = await Notification.requestPermission();
            $('#notifModal').modal('hide');

            if (permission === 'granted') {
                await this.setupNotifications(webPushApiClient);
            }
        } catch (error) {
            console.error('Permission request failed:', error);
            $('#notifModal').modal('hide');
            throw error;
        }
    }

    private setupMessageHandler(): void {
        if (!this._messaging) return;
    
        onMessage(this._messaging, (payload: NotificationPayload) => {
            if (Notification.permission !== "granted" || !payload.notification) return;
    
            const notification = new Notification(payload.notification.title || 'New Notification', {
                body: payload.notification.body || '',
                icon: payload.notification.icon || '/default-icon.png'
            });
    
            notification.onclick = (event) => {
                event.preventDefault();
                if (payload.data?.redirectUrl) {
                    window.open(payload.data.redirectUrl, '_blank');
                }
                notification.close();
            };
        });
    }
    

    private setupPermissionChangeListener(webPushApiClient: WebPushApiClient): void {
        if (!('permissions' in navigator)) return;

        navigator.permissions.query({ name: 'notifications' as PermissionName })
            .then(permissionStatus => {
                permissionStatus.onchange = async () => {
                    if (permissionStatus.state === 'denied' && this._currentToken && this._messaging) {
                        await deleteToken(this._messaging);
                        await webPushApiClient.deleteToken(this._currentToken);
                        this._currentToken = null;
                        this._initialized = false;
                    }
                };
            });
    }

    private getSupportedOS(): boolean {
        if (!("navigator" in window)) return false;
        
        const platform = (navigator.userAgent || navigator.platform)?.toLowerCase();
        return platform.includes('windows nt') || 
               platform.includes('x11') || 
               platform.includes('macintosh');
    }

    private renderNotificationModal(): void {
        const viewModel = { showModal: true };
        const html = Mustache.render(template, viewModel);
        $('#notificationModalWrap').html(html);
    }
}