import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./profile-employer-contract-view.html";
import { CompanyApiClient } from "../ts/clients/company-api-client";
import { ContractFormData } from "../ts/models/ContractFormData";
import { Notyf } from "notyf";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { FullscreenLoader } from "./fullscreen-loader";


@injectable()
export class ProfileEmployerContractView {
    private _translationService: TranslationService;
    private _translations: any;
    private _companyApiClient: CompanyApiClient;
    public _selectedCompany: any;
    private _formElement: JQuery<HTMLElement> | null;
    private _contract: any;
    private contract: any;
    private _fullShreenLoader: FullscreenLoader;
    private _notyf: Notyf;

    public constructor(
        companyApiClient: CompanyApiClient,
        translationService: TranslationService,
        notyf: Notyf,
        private __currentUserAccessor: CurrentUserAccessor,
        fullScreenLoader: FullscreenLoader
        ) {
        this._companyApiClient = companyApiClient;
        this._translationService = translationService;
        this._translations = null;
        this._formElement = null;
        this._selectedCompany = null;
        this._fullShreenLoader = fullScreenLoader;
        this._notyf = notyf;
      
    }

    public async load(): Promise<void> {
        try {

            await this._renderData();
            await this._initForm();
        
        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(): Promise<void> {
        // Build a view model from the API data

       -await this.__currentUserAccessor.getUser(true);

        this._translations = this._translationService.currentTranslations;

        await this._companyApiClient.getByUser().then((response: any) => {;
       
        const data = response?.data || response;
            
        let selectedCompany = null;
        let address = "";
        let postCode = "";
        let city = "";
    
        if (data) { 
            this._contract = data;
            if (data.companyAddress) {
                const addressParts = data.companyAddress.split(",");
                address = addressParts[0] || "";
                if (addressParts[1]) {
                    const postParts = addressParts[1].trim().split(" ");
                    postCode = postParts[0] || "";
                    city = postParts[1] || "";
                }
            }

            this.contract = data;
    
            selectedCompany = {
                id: data.companyId,
                text: data.companyName,
                address: address,
                postCode: postCode,
                city: city,
                vatNumber: data.companyVatNumber,
                registrationNumber: data.companyRegistrationNumber,
                shortName: data.companyShortName,
                companyRepName: data.companyRepresentativeName,
                contractOwnerName: data.contractOwnerName,
                contractOwnerPhone: data.contractOwnerPhone,
                contractOwnerEmail: data.contractOwnerEmail,
                DPO_OwnerPhone: data.dpoPhone,
                DPO_OwnerEmail: data.dpoEmail,
            
            };
        }
    
        const viewModel = {
            translations: this._translations,
            selectedCompany: selectedCompany,
            hasContract: !!data,
            showStatus: data?.status === 2,
            disabled: data?.status === 0 || data?.status === 2,
        };
    
        const htmlTemplate = template;
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
        this._initForm();
    });
      
    }

    private async _rerenderData(): Promise<void> {

        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;
        const address = this._selectedCompany?.address?.split(",")[0] || "";
        let post = this._selectedCompany?.address?.split(",")[1].trim() || "";
        const postCode = post.split(" ")[0] || "";
        const city = post.split(" ")[1] || "";

        const viewModel = {
            translations: this._translations,
            contract:   this._contract,
            hasContract:  this._contract != null,
            disabled:  this.contract?.status === 0 ||  this.contract?.status === 2,
            selectedCompany: {
                id: this._selectedCompany?.id || "",
                text: this._selectedCompany?.text || "",
                address: address || "",
                postCode: postCode || "",
                city: city || "",
                vatNumber: this._selectedCompany.vatNumber || "",
                registrationNumber: this._selectedCompany.registrationNumber || "",
                shortName: this._selectedCompany.shortName,
                companyRepName: '',
                contractOwnerName: '',
                contractOwnerPhone: '',
                contractOwnerEmail: '',
                DPO_OwnerPhone: '',
                DPO_OwnerEmail: '',
            },
        } as any;

        const htmlTemplate = template;
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
        this._initForm();
    }

    private async _initForm() {
        this._dodajValidacijskeFunkcije();
        this._formElement = $(("#contract-form"));
        $.validator.unobtrusive.parse(this._formElement);
        this._formElement.validate();


        var btnSubmit = $("#btn-submit");
        if (!btnSubmit) return;

        await this._initSearchFields();
        this.initFormHandlers();
    
    }

    private async _initSearchFields() {
        require('select2');
        let companyName = $(".company-search-field");
        companyName.select2({
            ajax: (await this._companyApiClient.companySearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.company-search-field').data("select2").dropdown.$search.val()
                }, false);
                settings.error = function () { };
            })) as any,
            placeholder: "Začnite tipkati in poiščite organizacijo",
            minimumInputLength: 3,
            allowClear: true,
            language: {
                inputTooShort: function () {
                    return 'Za iskanje vnesite vsaj tri znake.';
                },
                noResults: function () {
                    return 'Ni rezultatov iskanja.'
                },
                searching: function () {
                    return 'Iskanje...'
                }
            }
        });
        var chosenCompany = $("[name=company-search-hidden]").val() as string;
        companyName.append(new Option(chosenCompany, chosenCompany)).val(chosenCompany).trigger("change");
        companyName.on('select2:select', (e) => {
            this._selectedCompany = e.params.data;
            this._rerenderData();
        }).on('select2:select', function () {
            $(this).blur();
        }).on('select2:open', function (e) {
            (document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`) as HTMLElement).focus();
        });
    }

    private _dodajValidacijskeFunkcije(): void {
        $.validator.addMethod("numberIsValid", function (number) {
            number = number.replace(',', '.');
            var check = Number(number);
            if (isNaN(check)) {
                return false;
            }
            return true;
        });
    }


    private initFormHandlers(): void {
        $('#btn-download').off('click'); 
        $('#contract-form').off('click');
        $('#download-contract').off('click');

        $('#contract-form').on('submit', async (e) => {
            
            e.preventDefault();
            
            // Prevent double submission
            const $form = $(e.target);
            if ($form.data('submitted')) {
                return false;
            }
            
            $form.data('submitted', true);
            
            // Disable submit button
            const $submitBtn = $('#btn-submit');
            $submitBtn.prop('disabled', true);
            
            const formData = this.serializeForm();
            if (!formData) {
                // Re-enable form if validation fails
                $form.data('submitted', false);
                $submitBtn.prop('disabled', false);
                return;
            }
            
            try {
                await this.submitContractForm(formData);
                this.load();
            } catch (error) {
                // Re-enable form on error
                $form.data('submitted', false);
                $submitBtn.prop('disabled', false);
            }
        });
        


        $('#btn-download').on('click', async (e: any) => {
            e.preventDefault();
            await this.downloadContract(this._contract.id);
            this.load();
        });

        $('#download-contract').on('click', async (e: any) => {
            e.preventDefault();
            await this.downloadTemplate();
            this.load();
        });

    }

    private serializeForm(): ContractFormData  | null  {
        const $form = $('#contract-form');
        const form = $form[0] as HTMLFormElement; // Properly type the form element
        
        // Validate form before serialization
        if (!form.checkValidity()) {
            // Show built-in validation messages
            form.reportValidity();
            return null;
        }
        // Get all form values
        const formData = {
            companySearch: $('#company-search-hidden').val() as string,
            CompanyName: $('#companyName').val() as string,
            CompanyShortName: $('#companyShortName').val() as string,
            CompanyVatNumber: $('#taxNumber').val() as string,
            CompanyRegistrationNumber: $('#registrationNumber').val() as string,
            CompanyAddress: $('#companyAddress').val() + ', ' + $('#postcode').val() + ' ' + $('#city').val() as string,
            CompanyRepresentativeName: $('#companyRepresentative').val() as string,
            ContractOwnerName: $('#fullName').val() as string,
            ContractOwnerEmail: $('#emailContractOwner').val() as string,
            ContractOwnerPhone: $('#phoneContractOwner').val() as string,
            DpoEmail: $('#emailDPO').val() as string,
            DpoPhone: $('#phoneDPO').val() as string,
            CompanyId: this._selectedCompany?.id ?? this.contract?.companyId ?? null
        };

        return this.validateFormData(formData);
    }

    private validateFormData(formData: ContractFormData): ContractFormData {
        // Show validation messages
        (Object.keys(formData) as Array<keyof ContractFormData>).forEach(key => {
            const value = formData[key];
            const $field = $(`#${key}`);
            const $feedback = $field.siblings('.invalid-feedback');
            
            if (!value && $field.prop('required')) {
                $feedback.removeClass('d-none');
                $field.addClass('is-invalid');
            } else {
                $feedback.addClass('d-none');
                $field.removeClass('is-invalid');
            }
        });
    

        return formData;
    }

    private async submitContractForm(formData: ContractFormData): Promise<void> {

        this._fullShreenLoader.show();

        try {
            await this._companyApiClient.addContract(formData).then((response: any) => {
                if (response.success) {
                    this._notyf.success('Pogodba je bila uspešno pripravljena.');

                    this._fullShreenLoader.hide();
                    this.load();
                } else {
                    this._notyf.error('Prišlo je do napake pri pripravi pogodbe.');
                    this._fullShreenLoader.hide();

                }
            });
            
           
        } catch (error) {
            this._notyf.error('Prišlo je do napake pri komunikaciji s strežnikom.');
            console.error('Contract submission error:', error);
            this._fullShreenLoader.hide();

        }
    }

    private async downloadContract(contractId: number): Promise<void> {
        try {
            const blob = await this._companyApiClient.downloadContract(contractId);
            
            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `01_elektronski podpis_Pogodba obdelava osebnih podatkov_VZOREC2025.pdf`; // or get filename from headers
            
            // Append to body, click and remove
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            // Clean up
            window.URL.revokeObjectURL(url);
            
            this._notyf.success('Pogodba je bila uspešno prenesena.');
        } catch (error) {
            this._notyf.error('Prišlo je do napake pri prenosu pogodbe.');
            console.error('Contract download error:', error);
        }
    }


    private async downloadTemplate(): Promise<void> {
        try {
            const blob = await this._companyApiClient.downloadTemplate();
            
            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `01_elektronski podpis_Pogodba obdelava osebnih podatkov_VZOREC2025.pdf`; // or get filename from headers
            
            // Append to body, click and remove
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            // Clean up
            window.URL.revokeObjectURL(url);
            
            this._notyf.success('Pogodba je bila uspešno prenesena.');
        } catch (error) {
            this._notyf.error('Prišlo je do napake pri prenosu pogodbe.');
            console.error('Contract download error:', error);
        }
    }
    

}